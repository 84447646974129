
export function getUserData(){
	return JSON.parse(localStorage.getItem("userdata"));
}

export function getUserPower(){   //0就是学生   1就是管理员
	var t_userdata = getUserData();
	return t_userdata.power;
}

export function getUserState(){   //1就是管理员， 11就是分身用户
	var t_userdata = getUserData();
	return t_userdata.result;
}