<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
// import bus from "../common/bus";
 import {getUserState} from '../js/User.js'   //用户
export default {
	mounted() {
		var result = getUserState();
		if (result == 1) {
			this.InitAdmin();  //管理员
		} else if(result==11){
			this.InitFenshenUser(); //分身用户
		}
		
	},
    data() {
        return {
            items: []
        };
    },
	methods:{
		InitAdmin(){  //初始化管理员
			this.items = [
				{
					icon: "el-icon-lx-home",
					index: "dashboard",
					title: "系统首页"
				},
				{
					icon: "el-icon-menu",
					index: "",
					title: "会员管理",
					subs:[
						{
							title:'会员数据',
							index:'userdataAdmin'
						},
						// {
						// 	title:'用户浏览记录',
						// 	index:'BookManage'
						// }
					]
				},
				{
					icon: "el-icon-tickets",
					index: "table1",
					title: "内容管理",
					subs:[
						{
							title:'定时新闻',
							index:'TimingNews'
						},
						{
							title:'新闻发布',
							index:'news'
						},
						{
							title:'滚动图修改',
							index:'GunDonPicEdit'
						}
					]
				},
				{
					icon: "el-icon-star-off",
					index: "HeHuoAdmin",
					title: "合伙管理",
					subs:[
						{
							title:'分身用户',
							index:'FengShengUser'   //分身
						},
						{
							title:'客服管理',
							index:'ServiceEdit'   //分身
						}
					]
				},
				{
					icon: "el-icon-user",
					index: "table2",
					title: "用户管理",
					subs:[
						{
							title:'修改密码',
							index:'AdminChangePassword'
						}
					]
				}
			];
		},
		InitFenshenUser(){  //初始化分身用户版本
			this.items = [
				{
					icon: "el-icon-tickets",
					index: "table1",
					title: "内容管理",
					subs:[
						{
							title:'滚动图修改',
							index:'GunDonPicEdit'
						}
					]
				},
				{
					icon: "el-icon-star-off",
					index: "HeHuoAdmin",
					title: "合伙管理",
					subs:[
						{
							title:'客服管理',
							index:'ServiceEdit'   //分身
						}
					]
				}
			
			];
		}
		
	},
    computed: {
        onRoutes() {
            return this.$route.path.replace("/", "");
        },
        collapse(){
            return this.$store.state.collapse
        }
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
