import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/login'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
			{
				path: "/SouYe",
				name: "SouYe",
				meta: {
					title: '系统首页1'
				},
				component: () => import (
				/* webpackChunkName: "dashboard" */
				"../views/SouYe.vue")
			},
			{	
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                /* webpackChunkName: "dashboard" */
                "../views/Dashboard.vue")
            },{
                path: "/userdataAdmin",
                name: "userdataAdmin",
                meta: {
                    title: '会员数据'
                },
                component: () => import (
                /* webpackChunkName: "table" */
                "../ItemView/userAdmin/userdataAdmin.vue")
            }, {
                path: "/AdminUserdata",
                name: "AdminUserdata",
                meta: {
                    title: '个人信息'
                },
                component: () => import (
                /* webpackChunkName: "table" */
                "../ItemView/user/AdminUserdata.vue")
            }, {
                path: "/TimingNews",
                name: "TimingNews",
                meta: {
                    title: '定时新闻'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../ItemView/news/TimingNews.vue")
            }, {
                path: "/news",
                name: "news",
                meta: {
                    title: '新闻发布'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../ItemView/news/news.vue")
            }, {
                path: "/GunDonPicEdit",
                name: "GunDonPicEdit",
                meta: {
                    title: '滚动图修改'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../ItemView/content/GunDonPicEdit.vue")
            },{
                path: "/FengShengUser",
                name: "FengShengUser",
                meta: {
                    title: '分身用户'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../ItemView/sharewithAdmin/FengShengUser.vue")
            },{
                path: "/ServiceEdit",
                name: "ServiceEdit",
                meta: {
                    title: '客服管理'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../ItemView/sharewithAdmin/ServiceEdit.vue")
            },{
                path: "/AdminChangePassword",
                name: "AdminChangePassword",
                meta: {
                    title: '修改密码'
                },
                component: () => import (
                /* webpackChunkName: "donate" */
                "../ItemView/user/AdminChangePassword.vue")
            }, {
                path: "/permission",
                name: "permission",
                meta: {
                    title: '权限管理',
                    permission: true
                },
                component: () => import (
                /* webpackChunkName: "permission" */
                "../views/Permission.vue")
            }, {
                path: "/i18n",
                name: "i18n",
                meta: {
                    title: '国际化语言'
                },
                component: () => import (
                /* webpackChunkName: "i18n" */
                "../views/I18n.vue")
            }, {
                path: "/upload",
                name: "upload",
                meta: {
                    title: '上传插件'
                },
                component: () => import (
                /* webpackChunkName: "upload" */
                "../views/Upload.vue")
            }, {
                path: "/icon",
                name: "icon",
                meta: {
                    title: '自定义图标'
                },
                component: () => import (
                /* webpackChunkName: "icon" */
                "../views/Icon.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */
                '../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import (/* webpackChunkName: "403" */
                '../views/403.vue')
            }
        ]
    }, {
		path: "/login",
		name: "Login",
		meta: {
			title: '登录'
		},
		component: () => import (
		/* webpackChunkName: "login" */
		"../views/Login.vue")
	}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 聚铭优匠`;
	const role = localStorage.getItem('ms_username');
	console.log(to.path)
	// if (to.path == '/login') {
	// 	next('/login');
	// } else if (to.meta.permission) {
	// 	// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
	// 	role === 'admin'
	// 		? next()
	// 		: next('/403');
	// } else {
	// 	next();
	// }
	if (to.path == '/login') {
		next();
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		role === 'admin'
			? next()
			: next('/403');
	} else {
		next();
	}

});

export default router;